import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import ButtonLoader from './common/ButtonLoader';
import * as Yup from 'yup';
import { deBounce, fetchIdFromUrl, getTomorrowsDateObject, productList, roundPrice } from '../utils';
import AppConstants from '../AppConstants';
import DatePicker from 'react-datepicker';
import { getPriceByProductFreequency } from 'services/price';

interface Props {
  boxCount: number;
  frequency: number;
  onSubmit: Function;
  isSubmitting?: boolean;
  statusMessage?: string;
  suggestedFrequency?: number;
  enableBoxCountChange?: boolean;
  enableNextOrderDateChange?: boolean;
  nextOrderDate?: string;
  subscription?: any;
  dynamicPriceChange?: boolean | undefined;
}

export const FrequencyUpdateForm: React.FC<Props> = props => {
  const [nextOrderDate, setNextOrderDate] = useState(props.nextOrderDate);
  const [valueChanged, setValueChanges] = useState({ status: false, price: 0 });

  const validationSchema = Yup.object({
    frequency: Yup.number()
      .min(2)
      .max(200)
      .required('This is a required field')
      .label('Frequency'),
  });

  const findPrice = deBounce(async event => {
    if (!props?.dynamicPriceChange) return;
    const { subscription } = props;
    if (subscription && subscription?.pet) {
      const payload = {
        ...event,
        pet: +fetchIdFromUrl(subscription?.pet),
      };
      const response = await getPriceByProductFreequency(payload);
      if (response) {
        const { total_incl_tax_excl_discounts } = response;
        setValueChanges({ status: true, price: total_incl_tax_excl_discounts });
      }
    }
  }, 500);
  return (
    <>
      <Formik
        initialValues={{
          quantity: props.boxCount,
          frequency: props.frequency,
          nextOrderDate: props.nextOrderDate,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          props.onSubmit({
            ...values,
            nextOrderDate,
          })
          setValueChanges({ status: false, price: 0 });
        }
        }
      >
        {({
          handleSubmit,
          handleChange,
          values,
          //setFieldValue,
          errors,
          touched,
        }) => (
          <Form noValidate={true} onSubmit={handleSubmit}>
            <Form.Row className='manage-freequency-responsive'>
              {props.enableBoxCountChange && (
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Product Selection</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={event => {
                      handleChange(event);
                      findPrice({
                        frequency: +values.frequency,
                        product: +event.target.value,
                      });
                    }}
                    value={values.quantity && values.quantity.toString()}
                    name="quantity"
                    className="cust-sel"
                  >
                    {productList.map((it, index) => (
                      <option
                        key={'product' + index + it.label}
                        value={it.value}
                      >
                        {'1 ×	' + it.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Delivered every</Form.Label>
                <Form.Control
                  type="number"
                  as="input"
                  onChange={event => {
                    handleChange(event);
                    findPrice({
                      frequency: +event.target.value,
                      product: +values.quantity,
                    });
                  }}
                  value={values.frequency}
                  name="frequency"
                  className="cust-sel"
                  isInvalid={touched.frequency && !!errors.frequency}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.frequency}
                </Form.Control.Feedback>
              </Form.Group>
              {props.enableNextOrderDateChange && (
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Next Order Date</Form.Label>
                  <DatePicker
                    selected={new Date(nextOrderDate)}
                    onChange={value => setNextOrderDate(value)}
                    dateFormat={AppConstants.DATE_PICKER_FORMAT}
                    name="nextOrderDate"
                    minDate={getTomorrowsDateObject()}
                  />
                </Form.Group>
              )}
            </Form.Row>
            {props.statusMessage && (
              <div className="mgn-top-20 text-center text-primary txt-bold">
                {props.statusMessage}
              </div>
            )}
            {props?.dynamicPriceChange && valueChanged.status && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span>Price will be ${roundPrice(valueChanged.price)}</span>
              </div>
            )}
            <div className="text-center cust-modal-footer mgn-top-30">
              <ButtonLoader
                className="btn-def"
                type="submit"
                text="Update delivery frequency"
                loadingText="Updating"
                loading={props.isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

FrequencyUpdateForm.defaultProps = {
  enableBoxCountChange: false,
  enableNextOrderDateChange: false,
};
